import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { useBoolean } from '../../hooks/use-boolean'
import { Message } from '../../service/types/common'
import Typography from '@mui/material/Typography'
import { useAuthService } from '../../service/auth-service'
import RHFCode from '../../components/hook-form/rhf-code'
import { useForm } from 'react-hook-form'
import FormProvider from '../../components/hook-form'
import CircularProgress from '@mui/material/CircularProgress'

export default function FactorAuthView() {
    const [message, setMessage] = useState<Message>(null)
    const loading = useBoolean(false)
    const authService = useAuthService()
    const [code, setCode] = useState('')
    const [isConnected, setIsConnected] = useState(false)

    const methods = useForm({
        defaultValues: {
            inputCode: '',
        },
    })
    const {
        handleSubmit,
        reset,
    } = methods

    useEffect(() => {
        const checkStatus = async () => {
            loading.onTrue()
            await authService.get2FaQrCode()
                .then((data) => {
                    setCode(data)
                })
                .catch((e) => {
                    setIsConnected(true)
                })
                .finally(loading.onFalse)
        }
        void checkStatus()
    }, [isConnected])

    const remove2Fa = async (deleteCode: string) => {
        if (!deleteCode || deleteCode.length !== 6) {
            setMessage({ severity: 'error', text: 'Geçerli bir kod giriniz.' })
            return
        }
        loading.onTrue()
        await authService.remove2Fa(deleteCode)
            .then(() => {
                setMessage({ severity: 'success', text: 'Mobil uygulama başarıyla bağlantısı kaldırıldı.' })
                setIsConnected(false)
                reset({ inputCode: '' })
            })
            .catch((e) => {
                setMessage({ severity: 'error', text: e.message })
            })
            .finally(loading.onFalse)
    }

    const onSubmit = handleSubmit(async (data: { inputCode: string }) => {
        setMessage(null)
        loading.onTrue()
        await authService.verify2Fa(data.inputCode)
            .then(() => {
                setMessage({ severity: 'success', text: 'Mobil uygulama başarıyla bağlandı.' })
                setIsConnected(true)
                reset({ inputCode: '' })
            })
            .catch((e) => {
                setMessage({ severity: 'error', text: e.message })
            })
            .finally(loading.onFalse)
    })

    useEffect(() => {
        if (message) {
            setTimeout(() => {
                setMessage(null)
            }, 5000)
        }
    }, [message])

    if (loading.value) {
        return <Card sx={{ p: 2 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>İki Faktörlü Kimlik Doğrulama</Typography>
            <CircularProgress />
        </Card>
    }
    if (isConnected) {
        return <Delete2FaView remove2Fa={remove2Fa} loading={loading} message={message} />
    }

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Card sx={{ p: 2 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>İki Faktörlü Kimlik Doğrulama</Typography>
                <Box display="flex" flexDirection="column"
                     rowGap={1}>
                    <img width={200} height={200} src={`data:image/png;base64,${code}`} alt={'Data'} />
                </Box>
                <Stack spacing={2}
                       mb={2}
                       maxWidth={'sm'}
                       alignItems="flex-start" justifyContent="flex-start">
                    <Typography variant="body1">Giriş yaparken iki faktörlü kimlik doğrulamayı hızlı bir şekilde tamamlamak için Google
                        Authenticator&lsquo;da
                        veya herhangi bir iki faktörlü kimlik doğrulama uygulamasında QR kodunu tarayabilirsiniz.</Typography>
                    <RHFCode
                        name="inputCode"
                        sx={{ mt: 2 }}
                        length={6}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        type={'submit'}
                        sx={{ mt: 2 }}
                        disabled={loading.value}
                    >
                        Mobil Uygulamada Erişim Kodunu Doğrula
                    </Button>
                </Stack>

                <Alert severity="info" sx={{ mb: 2, width: '50%', fontWeight: 'bold' }}>
                    E-mail ve şifrenizle giriş yaptıktan sonra, uygulamanızdaki doğrulama kodunu girerek giriş yapabilirsiniz.
                    Mobil uygulama tabanlı iki faktörlü kimlik doğrulamayı veya e-posta tabanlı kimlik doğrulamayı her zaman kullanabilirsiniz.
                    Uygulamaya erişiminizi kaybettiyseniz, e-postayla her zaman kod alabilirsiniz.
                </Alert>
                {message && (
                    <Alert severity={message.severity} sx={{ mt: 2 }}>
                        {message.text}
                    </Alert>
                )}
            </Card>
        </FormProvider>
    )
}

type Delete2FaViewProps = {
    remove2Fa: (code: string) => void
    loading: { value: boolean, onTrue: () => void, onFalse: () => void }
    message: Message
}

function Delete2FaView({ remove2Fa, loading, message }: Delete2FaViewProps) {
    const methods = useForm({
        defaultValues: {
            inputCode: '',
        },
    })
    const {
        handleSubmit,
        reset,
    } = methods

    const onSubmit = handleSubmit(async (data: { inputCode: string }) => {
        remove2Fa(data.inputCode)
    })

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Card sx={{ p: 2 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>İki Faktörlü Kimlik Doğrulama</Typography>
                <Stack maxWidth={'sm'} spacing={2} alignItems={'flex-start'} alignContent={'flex-start'}>
                    <Alert severity="success" sx={{ mb: 2 }}>
                        Mobile uygulama ile İki faktörlü kimlik doğrulama aktif.
                    </Alert>
                    <Typography variant="body1">Mobil uygulama tabanlı iki faktörlü kimlik doğrulamayı kaldırmak için kod girin ve aşağıdaki butona
                        tıklayabilirsiniz.</Typography>

                    <RHFCode
                        name="inputCode"
                        sx={{ mt: 2 }}
                        length={6}
                    />
                    <Button
                        variant="contained"
                        color="error"
                        type={'submit'}
                        disabled={loading.value}
                    >
                        Mobil Uygulama Bağlantısını Kaldır
                    </Button>
                    {message && (
                        <Alert severity={message.severity} sx={{ mt: 2 }}>
                            {message.text}
                        </Alert>
                    )}
                </Stack>
            </Card>
        </FormProvider>
    )
}