import React, { useEffect, useState } from 'react'
import { FeatureCategorySaveRequest, ProductFeatureCategory } from '../../../service/types/product-feature'
import { useProductService } from '../../../service/crud/product-service'
import { useLocation } from 'react-router-dom'
import LinearProgressView from '../../../common/linear-progress-view'
import { useNotify } from 'react-admin'
import ProductEditor from './product-feature-category-edit'
import { BreadItem, useGlobalState } from '../../../layout/bread/global-context'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

export default function ProductFeatureCategoryEditView() {
    const productService = useProductService()
    const globalState = useGlobalState()
    const { state } = useLocation()
    const [existing, setExisting] = useState<ProductFeatureCategory | undefined>(undefined)
    const [loading, setLoading] = useState(true)
    const [productId, setProductId] = useState('')
    const notify = useNotify()
    useEffect(() => {
        if (!state) {
            setLoading(false)
            return
        }
        const isNew = state.isNew
        const productId = state.productId
        if (isNew) {
            setProductId(productId)
            setLoading(false)
        } else {
            const categoryId = state.categoryId
            setProductId(productId)
            if (categoryId) {
                void productService.getCategory(productId, categoryId).then((category) => {
                    setExisting(category)
                    const item: BreadItem = {
                        title: category.name.turkish,
                        icon: undefined,
                    }
                    globalState.dispatch?.({ displays: { [categoryId]: item } })
                }).finally(() => setLoading(false))
            }
        }
    }, [state])

    const onSave = async (category: FeatureCategorySaveRequest) => {
        if (existing) {
            await onEdit(category, existing)
        } else {
            try {
                const copy = { ...category }
                copy.features = copy.features.map((feature) => {
                    const { id, ...rest } = feature
                    return rest
                })
                await productService.addCategory(productId, copy)
                notify('Kategori başarıyla kaydedildi')
            } catch (e) {
                notify(`Bir hata oluştu. ${e.message}`, { type: 'error' })
            }
        }
    }

    const onEdit = async (category: FeatureCategorySaveRequest, existing: ProductFeatureCategory) => {
        try {
            const copy = { ...category }
            const existingFeaturesIds = existing.features.map((feature) => feature.id)
            copy.features = copy.features.map((feature) => {
                const { id, ...rest } = feature
                if (id && !existingFeaturesIds.includes(id)) {
                    return rest
                } else {
                    return feature
                }
            })
            await productService.updateCategory(productId, existing.id, copy)
            notify('Kategori başarıyla güncellendi')
        } catch (e) {
            notify(`Bir hata oluştu. ${e.message}`, { type: 'error' })
        }
    }

    if (loading || !productId) {
        return <LinearProgressView />
    }
    return <DndProvider backend={HTML5Backend}>
        <ProductEditor existing={existing} onSave={onSave} />
    </DndProvider>
}