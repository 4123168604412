import { AxiosInstance } from 'axios'
import { useRestService } from './base-service'
import { MarketSchedule } from './types/market-schedule'

export const useMarketScheduleService = () => {
    const api = useRestService()
    return new MarketScheduleService(api)
}

class MarketScheduleService {
    private readonly api: AxiosInstance

    constructor(api: AxiosInstance) {
        this.api = api
    }

    public async update(schedule: MarketSchedule[]): Promise<MarketSchedule[]> {
        return await this.api
            .post(`/market/schedule/save`, schedule, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((it) => it.data)
    }

    public async getAll(): Promise<MarketSchedule[]> {
        return await this.api.get('/market/schedule/get').then(it => it.data)
    }
}
