export type SequenceItem = {
    id: number
    title: string
    subtitle: string
}
export type SequenceUpdateItem = {
    id: number
    sequence: number
}

export enum SequencedResource {
    categories = 'categories',
    departments = 'departments',
    products = 'products',
    productCategory = 'products/features',
    faq = 'faq',
}