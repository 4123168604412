import React, { useEffect, useState } from 'react'
import { Button, Card, CardContent, Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { FeatureCategorySaveRequest, ProductFeatureCategory, ProductFeatureSaveRequest } from '../../../service/types/product-feature'
import FeatureDataGrid from './feature-data-grid'
import Alert from '@mui/material/Alert'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import FeatureDialog from './product-feature-save-dialog'

type Props = {
    existing?: ProductFeatureCategory,
    onSave: (category: FeatureCategorySaveRequest) => void
}

const emptyCategory: FeatureCategorySaveRequest = {
    id: '',
    name: { turkish: '', english: '' },
    description: { turkish: '', english: '' },
    features: [],
    minSelectable: 0,
    maxSelectable: 0,
}

const ProductEditor = ({ existing, onSave }: Props) => {
    const [category, setCategory] = useState<FeatureCategorySaveRequest>(existing ?? emptyCategory)
    const [selectedFeature, setSelectedFeature] = useState<ProductFeatureSaveRequest | undefined>(undefined)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [categoryValidation, setCategoryValidation] = useState('')
    const [canOnlyAddOne, setCanOnlyAddOne] = useState(false)

    useEffect(() => {
        if (category.features.filter((feature) => feature.activeByDefault).length > category.maxSelectable) {
            setCategoryValidation('Varsayılan olarak aktif olan özellik sayısı max seçilebilir değerden büyük olamaz')
        } else if (category.name.turkish === '' || category.name.english === '') {
            setCategoryValidation('Kategori adı boş olamaz')
        } else if (category.minSelectable > category.maxSelectable) {
            setCategoryValidation('Min seçilebilir değer max seçilebilir değerden büyük olamaz')
        } else if (category.features.length < category.minSelectable) {
            setCategoryValidation('Kategori için yeterli sayıda özellik eklemelisiniz')
        } else if (category.features.length === 0) {
            setCategoryValidation('Kategori için hiç özellik eklenmemiş')
        } else {
            setCategoryValidation('')
        }
    }, [category])

    const handleAddFeature = () => {
        setSelectedFeature(undefined)
        setDialogOpen(true)
    }

    const handleEditFeature = (feature: ProductFeatureSaveRequest) => {
        setSelectedFeature(feature)
        setDialogOpen(true)
    }

    const handleSaveFeature = (feature: ProductFeatureSaveRequest) => {
        if (selectedFeature) {
            setCategory({
                ...category,
                features: category.features.map((f) => f.id === feature.id ? feature : f),
            })
        } else {
            setCategory({
                ...category,
                features: [...category.features, { ...feature, id: Date.now().toString() }],
            })
        }
    }

    const handleDeleteFeature = (id: string | undefined) => {
        setCategory({
            ...category,
            features: category.features.filter((feature) => feature.id !== id),
        })
    }

    const handleRowOrderChange = (reorderedFeatures: ProductFeatureSaveRequest[]) => {
        setCategory({
            ...category,
            features: reorderedFeatures,
        })
    }

    useEffect(() => {
        if (canOnlyAddOne) {
            setCategory({
                ...category,
                minSelectable: 1,
                maxSelectable: 1,
            })
        }
    }, [canOnlyAddOne])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Stack spacing={1} maxWidth={'md'}>
                            <Typography variant="h5">{existing ? 'Kategori Guncelle' : 'Kategori Ekle'}</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Katorgi Adı (Türkçe)"
                                        required
                                        value={category.name.turkish}
                                        onChange={(e) => setCategory({ ...category, name: { ...category.name, turkish: e.target.value } })}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Katogori Adı (İngilizce)"
                                        required
                                        value={category.name.english}
                                        onChange={(e) => setCategory({ ...category, name: { ...category.name, english: e.target.value } })}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Min Seçilebilir"
                                        type="number"
                                        required
                                        disabled={canOnlyAddOne}
                                        value={category.minSelectable}
                                        onChange={(e) => setCategory({ ...category, minSelectable: parseInt(e.target.value) })}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Max Seçilebilir"
                                        type="number"
                                        required
                                        disabled={canOnlyAddOne}
                                        value={category.maxSelectable}
                                        onChange={(e) => setCategory({ ...category, maxSelectable: parseInt(e.target.value) })}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={canOnlyAddOne}
                                        onChange={(e) => setCanOnlyAddOne(e.target.checked)}
                                    />
                                }
                                label="Sadece bir tane özelliği seçilebilir"
                            />
                            {categoryValidation && <Alert severity="error">
                                {categoryValidation}
                            </Alert>
                            }
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h5">Özellikler</Typography>
                        <FeatureDataGrid
                            features={category.features}
                            onDeleteFeature={handleDeleteFeature}
                            onRowOrderChange={handleRowOrderChange}
                            onEditFeature={handleEditFeature}
                            onAddFeature={handleAddFeature}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="contained"
                                    color={'primary'}
                                    onClick={() => onSave(category)}
                                    disabled={categoryValidation.length > 0} size={'large'}>
                                Kaydet
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <FeatureDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onSave={handleSaveFeature}
                feature={selectedFeature}
            />
        </Grid>
    )
}

export default ProductEditor