import * as Yup from 'yup'
import { ProductInsertRequest } from '../../service/types/product'
import { addDays } from 'date-fns'

export const NewProductSchema = Yup.object().shape({
    name: Yup.string().required('Urun adi gerekli'),
    englishName: Yup.string().required('Ingilizce urun adi gerekli'),
    englishDescription: Yup.string().required('Ingilizce aciklama gerekli'),
    image: Yup.mixed().required('Resim gerekli'),
    barcodes: Yup.array().of(Yup.string().required()).required().min(1, 'Barkod gerekli'),
    categoryId: Yup.string().required('Katagori gerekli'),
    price: Yup.number().required().moreThan(0, 'Alis fiyati gerekli'),
    description: Yup.string().required('Aciklama gerekli'),
    tax: Yup.number().required('Vergi gerekli. 0.00 olabilir'),
    priceSale: Yup.number(),
    purchasePrice: Yup.number().moreThan(-1, 'Alış fiyatı negatif olamaz'),
    quantity: Yup.number().required('Stok gerekli'),
    amount: Yup.number().required('Miktar gerekli'),
    unitId: Yup.string().required('Birim gerekli'),
    minimumOrderLimit: Yup.number().required('Minimum siparis limiti gerekli'),
    maximumOrderLimit: Yup.number().required('Maksimum siparis limiti gerekli'),
    expirationDate: Yup.mixed(),
    secondCategoryId: Yup.string(),
})


export function productSchemaToRest(data: any, image: string): ProductInsertRequest {
    return {
        name: {
            turkish: data.name,
            english: data.englishName,
        },
        description: {
            turkish: data.description,
            english: data.englishDescription,
        },
        image,
        price: data.price,
        stock: data.quantity,
        newPrice: data.priceSale,
        barcodes: data.barcodes,
        tax: data.tax,
        unitId: data.unitId,
        amount: data.amount,
        categoryId: data.categoryId,
        minimumOrderLimit: data.minimumOrderLimit,
        maximumOrderLimit: data.maximumOrderLimit,
        expirationDate: typeof data.expirationDate === 'number' ? data.expirationDate : data.expirationDate?.getTime() ?? 0,
        secondCategoryId: data.secondCategoryId,
        purchasePrice: data.purchasePrice,
        lowStockLimit: data.lowStockLimit,
        available: data.available,
    }
}

export function productRestToSchema(currentProduct?: ProductInsertRequest): any {
    return {
        name: currentProduct?.name.turkish ?? '',
        description: currentProduct?.description.turkish ?? '',
        image: currentProduct?.image ?? '',
        price: currentProduct?.price ?? 0,
        quantity: currentProduct?.stock ?? 0,
        priceSale: currentProduct?.newPrice ?? 0,
        barcodes: currentProduct?.barcodes ?? [],
        tax: currentProduct?.tax ?? 0,
        unitId: currentProduct?.unitId ?? '',
        amount: currentProduct?.amount ?? 0,
        categoryId: currentProduct?.categoryId ?? '',
        minimumOrderLimit: currentProduct?.minimumOrderLimit ?? 0,
        maximumOrderLimit: currentProduct?.maximumOrderLimit ?? 0,
        expirationDate: currentProduct?.expirationDate ? new Date(currentProduct.expirationDate) :  addDays(new Date(), 30),
        secondCategoryId: currentProduct?.secondCategoryId ?? '',
        englishName: currentProduct?.name.english ?? '',
        englishDescription: currentProduct?.description.english ?? '',
        purchasePrice: currentProduct?.purchasePrice ?? 0,
        lowStockLimit: currentProduct?.lowStockLimit ?? 0,
        available: currentProduct?.available ?? true,
    }
}