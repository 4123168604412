import { Controller, useFormContext } from 'react-hook-form'
import React from 'react'
import Switch from '@mui/material/Switch'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'

interface Props extends Omit<FormControlLabelProps, 'control'> {
    name: string
    helperText?: React.ReactNode
}

export default function RHFSwitch({ name, helperText, ...other }: Props) {
    const { control } = useFormContext()

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <div>
                    <FormControlLabel control={<Switch {...field} checked={field.value} />} {...other} />

                    {(!!error || helperText) && (
                        <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
                    )}
                </div>
            )}
        />
    )
}
