import React from 'react'
import { Button, Container } from '@mui/material'
import ProductFeatureCategoryListView from './product-feature-category-list-view'
import Iconify from '../../../components/iconify'
import { useLocation, useNavigate } from 'react-router-dom'
import Stack from '@mui/material/Stack'

const ProductFeatureCategoryView = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const idFromPath = () => {
        const path = location.pathname
        const parts = path.split('/')
        if (parts.length < 2) {
            return ''
        }
        return parts[parts.length - 2]
    }
    const handleAddCategory = () => {
        const state = location.state
        const productId = state != null ? state.productId : idFromPath()
        navigate(`/products/${productId}/features/new`, { state: { productId, isNew: true } })
    }
    const rearrange = () => {
        const state = location.state
        const productId = state != null ? state.productId : idFromPath()
        navigate(`/products/${productId}/features/rearrange`, { state: { productId } })
    }
    return (
        <Container maxWidth={false}>
            <Stack justifyContent="right" direction={'row'} spacing={2} alignItems="center" my={2}>
                <Button variant={'contained'} onClick={rearrange}>
                    <Iconify icon={'mdi:arrow-expand-vertical'} />
                    Sıralamayı Değiştir
                </Button>
                <Button variant="contained" onClick={handleAddCategory}>
                    <Iconify icon={'mdi:plus'} />
                    Yeni Kategori Ekle
                </Button>
            </Stack>
            <ProductFeatureCategoryListView />
        </Container>
    )
}

export default ProductFeatureCategoryView