import { AxiosInstance } from 'axios'
import { SequencedResource, SequenceItem } from './types/sequence'
import { useRestService } from './base-service'

export const useSequenceService = () => {
    const api = useRestService()
    return new SequenceService(api)
}

class SequenceService {
    private readonly api: AxiosInstance

    constructor(api: AxiosInstance) {
        this.api = api
    }

    public async getSequenced(resource: SequencedResource | string, search?: string): Promise<SequenceItem[]> {
        return await this.api.get(`/${resource}/sequenced/get`, {
            params: { search },
        }).then(it => it.data)
    }

    public async updateSequence(resource: SequencedResource | string, sequence: SequenceItem[]) {
        const request = sequence.map((it, index) => ({ id: it.id, order: index }))
        return await this.api.put(`/${resource}/sequenced/update`, request).then(it => it.data)
    }
}