import React from 'react'
import { Create, Datagrid, DeleteWithConfirmButton, Edit, EditButton, SimpleForm, TextField, TextInput } from 'react-admin'
import { TimeField } from '../../../common/TimeField'
import { RaFixedList } from '../../../common/ra-fixed-list'

export const ProductUnitList = () => {
    return (
        <RaFixedList>
            <Datagrid bulkActionButtons={false}>
                <TextField source="name" />
                <TextField source="abbreviation.turkish" />
                <TimeField source="createdAt" />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </RaFixedList>
    )
}

export const ProductUnitCreate = () => {
    return (
        <Create redirect={'list'}>
            <SimpleForm>
                <TextInput source="name" name={'name'} />
                <TextInput source="abbreviation.turkish" name={'abbreviation.turkish'} />
                <TextInput source="abbreviation.english" name={'abbreviation.english'} />
            </SimpleForm>
        </Create>
    )
}

export const ProductUnitEdit = () => {
    return (
        <Edit redirect={'list'}>
            <SimpleForm>
                <TextInput source="name" name={'name'} />
                <TextInput source="abbreviation.turkish" name={'abbreviation.turkish'} />
                <TextInput source="abbreviation.english" name={'abbreviation.english'} />
            </SimpleForm>
        </Edit>
    )
}

export const ProductUnitTranslations = {
    tr: {
        productUnits: {
            name: 'Ürün Birimleri',
            fields: {
                name: 'Adı',
                createdAt: 'Oluşturulma Tarihi',
                abbreviation: {
                    turkish: 'Türkçe Kısalt',
                    english: 'İngilizce Kısalt',
                },
            },
        },
    },
}
