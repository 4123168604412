import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'
import FormProvider from 'src/components/hook-form'
import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import { useLocation, useNavigate } from 'react-router-dom'
import RHFCode from '../components/hook-form/rhf-code'
import EmailInboxIcon from '../images/email-inbox-icon'
import { useAuthService } from '../service/auth-service'
import { useNotify } from 'ra-core'
import { useBoolean } from '../hooks/use-boolean'
import { VerificationMethod } from '../service/types/user'

type Props = {
    token: string
    method: VerificationMethod
}

export default function LoginCodeView({ token, method }: Props) {
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const location = useLocation()
    const notify = useNotify()
    const userService = useAuthService()
    const loading = useBoolean(false)
    const [resendRemaining, setResendRemaining] = useState(method === VerificationMethod.googleAuth ? 0 : 90)
    const [verificationMethod, setVerificationMethod] = useState<VerificationMethod>(method)
    const [authToken, setAuthToken] = useState(token)

    const NewPasswordSchema = Yup.object().shape({
        code: Yup.string().min(4, '').required(''),
    })

    const defaultValues = {
        code: '',
    }

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(NewPasswordSchema),
        defaultValues,
    })

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods

    const onSubmit = handleSubmit(async (data) => {
        try {
            setError('')
            await userService.verify(authToken, data.code)
            navigate(location.state ? location.state.nextPathname : '/')
        } catch (error) {
            setError(error.message)
        }
    })

    const onResend = async () => {
        loading.onTrue()
        try {
            setError('')
            const result = await userService.resendCode(authToken)
            if (method === VerificationMethod.googleAuth) {
                setVerificationMethod(VerificationMethod.email)
                setAuthToken(result.token)
                notify('Onay kodu gönderildi. Lütfen e-postanızı kontrol ediniz.', { type: 'success' })
            } else {
                notify('Onay kodu tekrar gönderildi.', { type: 'success' })
            }
            setResendRemaining(90)
        } catch (error) {
            setError(error.message)
        } finally {
            loading.onFalse()
        }
    }
    useEffect(() => {
        if (resendRemaining === 0 || verificationMethod !== VerificationMethod.email) {
            return
        }
        const interval = setInterval(() => {
            if (resendRemaining > 0) {
                setResendRemaining(resendRemaining - 1)
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [resendRemaining, verificationMethod])

    const renderForm = (
        <Stack spacing={3} alignItems="center">
            <RHFCode name="code" length={6} />

            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting || loading.value}
            >
                Onayla
            </LoadingButton>

            <LoadingButton
                size="small"
                variant="soft"
                onClick={onResend}
                color={'info'}
                disabled={resendRemaining !== 0}
                loading={isSubmitting || loading.value}
            >
                {verificationMethod === VerificationMethod.googleAuth ? 'Onay Kodunu E-posta ile Gönder' : 'Onay Kodunu Tekrar Gönder' + (resendRemaining !== 0 ? ` (${resendRemaining})s` : '')}
            </LoadingButton>

        </Stack>
    )

    const renderHead = (
        <>
            <EmailInboxIcon sx={{ height: 96 }} />
            <Stack spacing={1} sx={{ my: 5 }}>
                <Typography variant="h3">{'Lütfen Girişi Onaylayın'}</Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {verificationMethod === VerificationMethod.googleAuth ? 'İki faktör uygulamasından gelen onay kodunu giriniz.' : 'E-posta adresinize gönderilen onay kodunu giriniz.'}
                </Typography>

                <Alert sx={{ mt: 2 }} severity="warning">
                    {verificationMethod === VerificationMethod.googleAuth ? 'Eğer i̇ki faktör uygulamasından kod giremiyorsunuz, E-posta ile kod isteyebilirsiniz' : 'Eğer onay kodunuzu gelen kutunuzda bulamıyorsanız, lütfen spam ya da gereksiz e-postalar klasörünüzü kontrol ediniz.'}
                </Alert>
                {error && <Alert severity="error">{error}</Alert>}
            </Stack>
        </>
    )

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderHead}

            {renderForm}
        </FormProvider>
    )
}
