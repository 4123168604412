import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import Iconify from '../components/iconify'
import { InputAdornment } from '@mui/material'
import { useController } from 'react-hook-form'

export function SearchInput({ source, notLive, label, alwaysOn }) {
    const [value, setValue] = useState('')
    const { field } = useController({ name: source })

    function sendResult() {
        let text = value.trim()
        field.onChange(text)
        setValue('')
    }

    function onInputChange(event) {
        setValue(event.target.value)
        if (!notLive) {
            field.onChange(event.target.value)
        }
    }

    return (
        <TextField
            label={label}
            onChange={onInputChange}
            value={value}
            size={'small'}
            onKeyPress={(e) => {
                e.persist()
                if (e.key === 'Enter') {
                    sendResult()
                } else {
                    return null
                }
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" onClick={sendResult}>
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                ),
            }}
        />
    )
}
