import React, { useCallback, useState } from 'react'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Container from '@mui/material/Container'
import Iconify from 'src/components/iconify'
import { useSettingsContext } from 'src/components/settings'
import AccountGeneral from '../account-general'
import AccountChangePassword from '../account-change-password'
import PaymentTypesView from '../payment-types-view'
import FactorAuthView from '../factor-auth-view'
import MarketScheduleEditor from '../market-schedule-editor'

export default function AccountView() {
    const settings = useSettingsContext()

    const [currentTab, setCurrentTab] = useState('general')

    const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue)
    }, [])

    return (
        <Container sx={{ mt: 1 }} maxWidth={settings.themeStretch ? false : 'xl'}>
            <Tabs
                value={currentTab}
                onChange={handleChangeTab}
                sx={{
                    mb: { xs: 2, md: 2 },
                }}
            >
                <Tab
                    key="general"
                    label={'Market Genel'}
                    icon={<Iconify icon="solar:user-id-bold" width={24} />}
                    value="general"
                />
                <Tab
                    key="security"
                    label={'Güvenlik'}
                    icon={<Iconify icon="ic:round-vpn-key" width={24} />}
                    value="security"
                />
                <Tab
                    key="2fa"
                    label={'İki Faktörlü Doğrulama'}
                    icon={<Iconify icon="solar:folder-security-bold-duotone" width={24} />}
                    value="2fa"
                />
                <Tab
                    key="payment-types"
                    label={'Ödeme Yöntemleri'}
                    icon={<Iconify icon="fluent:payment-24-filled" width={24} />}
                    value="payment-types"
                />
                <Tab key={'schedule'}
                     label={'Çalışma Saatleriniz'}
                     icon={<Iconify icon="ic:round-schedule" width={24} />}
                     value="schedule" />
            </Tabs>

            {currentTab === 'general' && <AccountGeneral />}
            {currentTab === 'security' && <AccountChangePassword />}
            {currentTab === '2fa' && <FactorAuthView />}
            {currentTab === 'payment-types' && <PaymentTypesView />}
            {currentTab === 'schedule' && <MarketScheduleEditor />}
        </Container>
    )
}
