import React, { useEffect, useState } from 'react'
import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Stack, TextField } from '@mui/material'
import { ProductFeatureSaveRequest } from '../../../service/types/product-feature'

type FeatureDialogProps = {
    open: boolean
    onClose: () => void
    onSave: (feature: ProductFeatureSaveRequest) => void
    feature?: ProductFeatureSaveRequest
}

const defaultFeature: ProductFeatureSaveRequest = {
    id: '-1',
    name: { turkish: '', english: '' },
    description: { turkish: '', english: '' },
    price: 0,
    activeByDefault: false,
    categoryId: '',
    sequence: 0,
    mandatory: false,
}

const FeatureDialog = ({ open, onClose, onSave, feature }: FeatureDialogProps) => {
    const [currentFeature, setCurrentFeature] = useState<ProductFeatureSaveRequest>(feature ?? defaultFeature)
    const [featureValidation, setFeatureValidation] = useState('')

    useEffect(() => {
        setCurrentFeature(feature ?? { ...defaultFeature })
    }, [feature])

    useEffect(() => {
        if (currentFeature.name.turkish === '' || currentFeature.name.english === '') {
            setFeatureValidation('Özellik adı boş olamaz')
        } else {
            setFeatureValidation('')
        }
    }, [currentFeature])

    const handleSave = () => {
        if (featureValidation === '') {
            onSave(currentFeature)
            onClose()
        }
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{feature ? 'Özelliği Düzenle' : 'Yeni Özellik Ekle'}</DialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Özellik Adı (Türkçe)"
                                value={currentFeature.name.turkish}
                                onChange={(e) => setCurrentFeature({ ...currentFeature, name: { ...currentFeature.name, turkish: e.target.value } })}
                                fullWidth
                                required
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Özellik Adı (İngilizce)"
                                value={currentFeature.name.english}
                                required
                                onChange={(e) => setCurrentFeature({
                                    ...currentFeature,
                                    name: { ...currentFeature.name, english: e.target.value },
                                })}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Açıklama (Türkçe)"
                                value={currentFeature.description.turkish}
                                onChange={(e) => setCurrentFeature({
                                    ...currentFeature,
                                    description: { ...currentFeature.description, turkish: e.target.value },
                                })}
                                fullWidth
                                margin="normal" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Açıklama (İngilizce)"
                                value={currentFeature.description.english}
                                onChange={(e) => setCurrentFeature({
                                    ...currentFeature,
                                    description: { ...currentFeature.description, english: e.target.value },
                                })}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        label="Fiyat"
                        type="number"
                        value={currentFeature.price}
                        error={isNaN(parseFloat(String(currentFeature.price)))}
                        helperText={isNaN(parseFloat(String(currentFeature.price))) ? 'Lütfen sayısal bir değer giriniz' : ''}
                        onBlur={(e) => {
                            const parsed = parseFloat(e.target.value)
                            if (!isNaN(parsed)) {
                                setCurrentFeature({ ...currentFeature, price: parsed })
                            }
                        }}
                        onChange={(e) => {
                            const value = e.target.value
                            // @ts-ignore
                            setCurrentFeature({ ...currentFeature, price: value })
                        }}
                        margin="normal"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={currentFeature.activeByDefault}
                                onChange={(e) => setCurrentFeature({ ...currentFeature, activeByDefault: e.target.checked })}
                            />
                        }
                        label="Varsayılan olarak aktif"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={currentFeature.mandatory}
                                onChange={(e) => setCurrentFeature({ ...currentFeature, mandatory: e.target.checked })}
                            />
                        }
                        label="Silinmız (Üründan çıkarılamaz)"
                    />
                    {featureValidation && <Alert severity="error">
                        {featureValidation}
                    </Alert>
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">İptal</Button>
                <Button onClick={handleSave} color="primary"
                        disabled={featureValidation.length > 0 || isNaN(parseFloat(String(currentFeature.price)))}>Kaydet</Button>
            </DialogActions>
        </Dialog>
    )
}

export default FeatureDialog