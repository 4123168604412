import React, { useEffect, useState } from 'react'
import { CircularProgress, Paper, Table, TableBody, TableContainer } from '@mui/material'
import ProductFeatureCategoryItem from './product-feature-category-item'
import { ProductFeatureCategory } from '../../../service/types/product-feature'
import { useProductService } from '../../../service/crud/product-service'
import { TableHeadCustom } from '../../../components/table'
import { useLocation, useNavigate } from 'react-router-dom'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'


export default function ProductFeatureCategoryListView() {
    const [categories, setCategories] = useState<ProductFeatureCategory[]>([])
    const [loading, setLoading] = useState(true)
    const productService = useProductService()
    const location = useLocation()
    const navigate = useNavigate()
    const [productId, setProductId] = useState<string>('')

    const productIdFromPath = () => {
        const path = location.pathname
        const split = path.split('/')
        if (split.length < 2) {
            return ''
        }
        return split[split.length - 2]
    }

    useEffect(() => {
        const state = location.state
        const productId = state === null ? productIdFromPath() : state.productId
        if (!productId) {
            return
        }
        setProductId(productId)
        const fetchCategories = async () => {
            try {
                const fetchedCategories = await productService.getFeatures(productId)
                setCategories(fetchedCategories)
            } catch (error) {
                console.error('Failed to fetch product feature categories:', error)
            } finally {
                setLoading(false)
            }
        }

        void fetchCategories()
    }, [location])

    const handleEdit = (category: ProductFeatureCategory) => {
        const state = { productId, categoryId: category.id }
        navigate(`/products/${productId}/features/${category.id}`, { state })
    }

    if (loading) {
        return <CircularProgress />
    }
    if (categories.length === 0) {
        return <Box display="flex" justifyContent="center" alignItems="center" my={2}>
            <Alert severity="info" sx={{ maxWidth: 700 }}> Ürün özellikleri bulunmadı. Yeni bir kategori eklemek için yukarıdaki butonu
                kullanın.</Alert>
        </Box>
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHeadCustom
                    headLabel={[
                        { id: '', label: '' },
                        { id: 'name', label: 'Türkçe Adı' },
                        { id: 'description', label: 'Altyazı' },
                        { id: 'minSelectable', label: 'Min Seçilebilir' },
                        { id: 'maxSelectable', label: 'Max Seçilebilir' },
                        { id: 'features', label: 'Toplam Özellığı' },
                        { id: 'actions', label: 'İşlemler' },
                    ]}
                />
                <TableBody>
                    {categories.map((category) => (
                        <ProductFeatureCategoryItem key={category.id} onEdit={handleEdit} category={category} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
};