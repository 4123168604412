import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useRouter } from '../../../routes/hooks'
import RearrangeView from '../../re-arrange/rearrange-view'

export default function ProductFeatureCategoryRearrangeView() {
    const router = useRouter()
    const { state } = useLocation()
    const [productId, setProductId] = useState('')
    useEffect(() => {
        if (!state) {
            router.back()
        }
        const productId = state.productId
        if (!productId) {
            router.back()
        }
        setProductId(productId)
    }, [state])

    if (!productId) {
        return <></>
    }
    return (
        <RearrangeView resource={`products/${productId}/features`} />
    )
}