import { BaseCrudService } from '../base-crud-service'
import { Product, ProductInsertRequest } from '../types/product'
import { AxiosInstance } from 'axios'
import { useRestService } from '../base-service'
import { FeatureCategorySaveRequest, ProductFeatureCategory } from '../types/product-feature'

export const useProductService = () => {
    const api = useRestService()
    return new ProductService(api)
}

class ProductService extends BaseCrudService<Product, ProductInsertRequest> {
    constructor(api: AxiosInstance) {
        super(api, '/products')
    }

    public async search(query: string): Promise<Product[]> {
        return await this.api.get<Product[]>(`${this.resource}/search`, {
            params: { query },
        }).then((response) => response.data)
    }

    public async saveFeatures(productId: string, features: FeatureCategorySaveRequest[]): Promise<ProductFeatureCategory[]> {
        const endpoint = `${this.resource}/${productId}/features/save`
        return await this.api.post<ProductFeatureCategory[]>(endpoint, features)
            .then((response) => response.data)
    }

    public async getFeatures(productId: string): Promise<ProductFeatureCategory[]> {
        const endpoint = `${this.resource}/${productId}/features/get`
        return await this.api.get<ProductFeatureCategory[]>(endpoint)
            .then((response) => response.data)
    }

    public async getCategory(productId: string, categoryId: string): Promise<ProductFeatureCategory> {
        const endpoint = `${this.resource}/${productId}/features/get/${categoryId}`
        return await this.api.get<ProductFeatureCategory>(endpoint)
            .then((response) => response.data)
    }

    public async deleteCategory(productId: string, categoryId: string): Promise<void> {
        const endpoint = `${this.resource}/${productId}/features/delete/${categoryId}`
        return await this.api.delete(endpoint)
    }

    public async updateCategory(productId: string, categoryId: string, category: FeatureCategorySaveRequest): Promise<ProductFeatureCategory> {
        const endpoint = `${this.resource}/${productId}/features/update/${categoryId}`
        return await this.api.put<ProductFeatureCategory>(endpoint, category)
            .then((response) => response.data)
    }

    public async addCategory(productId: string, category: FeatureCategorySaveRequest): Promise<ProductFeatureCategory> {
        const endpoint = `${this.resource}/${productId}/features/add`
        return await this.api.post<ProductFeatureCategory>(endpoint, category)
            .then((response) => response.data)
    }
}