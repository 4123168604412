import React, { useEffect, useRef } from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { ProductFeatureSaveRequest } from '../../../service/types/product-feature'
import { useDrag, useDrop } from 'react-dnd'
import Button from '@mui/material/Button'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Iconify from '../../../components/iconify'
import { fCurrencyTl } from '../../../utils/format-number'
import Label from '../../../components/label'


type DraggableRowProps = {
    feature: ProductFeatureSaveRequest;
    index: number;
    moveRow: (dragIndex: number, hoverIndex: number) => void;
    onDeleteFeature: (id: string | undefined) => void;
    onEditFeature: (feature: ProductFeatureSaveRequest) => void;
};


const DraggableRow: React.FC<DraggableRowProps> = ({ feature, onEditFeature, index, moveRow, onDeleteFeature }) => {
    const ref = useRef<HTMLTableRowElement>(null)

    const [, drop] = useDrop({
        accept: 'row',
        hover(item: { index: number }) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            if (dragIndex === hoverIndex) {
                return
            }

            moveRow(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: 'row',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    useEffect(() => {
        if (ref.current) {
            drag(drop(ref))
        }
    }, [ref])

    return (
        <TableRow ref={ref} key={feature.id} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <TableCell>
                <DragIndicatorIcon style={{ cursor: 'move' }} />
            </TableCell>
            <TableCell>{feature.name.turkish}</TableCell>
            <TableCell>{feature.description.turkish}</TableCell>
            <TableCell>{fCurrencyTl(feature.price)}</TableCell>
            <TableCell><Label variant={'soft'} color={'info'}>{feature.activeByDefault ? 'Evet' : 'Hayir'}</Label></TableCell>
            <TableCell><Label variant={'soft'} color={'info'}>{feature.mandatory ? 'Evet' : 'Hayir'}</Label></TableCell>
            <TableCell>
                <Stack direction="row" spacing={1}>
                    <Button variant="text" color="primary"
                            startIcon={<Iconify icon={'mdi:pencil'} />}
                            onClick={() => onEditFeature(feature)}>
                        Düzenle
                    </Button>
                    <Button variant="text"
                            startIcon={<Iconify icon={'mdi:delete'} />}
                            color="error" onClick={() => onDeleteFeature(feature.id)}>
                        Sil
                    </Button>
                </Stack>
            </TableCell>
        </TableRow>
    )
}


type FeatureDataGridProps = {
    features: ProductFeatureSaveRequest[];
    onDeleteFeature: (id: string | undefined) => void;
    onRowOrderChange: (features: ProductFeatureSaveRequest[]) => void;
    onEditFeature: (feature: ProductFeatureSaveRequest) => void;
    onAddFeature: () => void;
};

const FeatureDataGrid: React.FC<FeatureDataGridProps> = ({ features, onDeleteFeature, onAddFeature, onEditFeature, onRowOrderChange }) => {
    const moveRow = (dragIndex: number, hoverIndex: number) => {
        const draggedFeature = features[dragIndex]
        const updatedFeatures = [...features]
        updatedFeatures.splice(dragIndex, 1)
        updatedFeatures.splice(hoverIndex, 0, draggedFeature)

        onRowOrderChange(updatedFeatures)
    }

    return (
        <Stack spacing={2}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Adı (Türkçe)</TableCell>
                            <TableCell>Açıklama (Türkçe)</TableCell>
                            <TableCell>Fiyat</TableCell>
                            <TableCell>Varsayılan Olarak Aktif</TableCell>
                            <TableCell>Silenmez (Üründan çikarlmaz)</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {features.map((feature, index) => (
                            <DraggableRow
                                key={feature.id}
                                index={index}
                                onEditFeature={onEditFeature}
                                feature={feature}
                                moveRow={moveRow}
                                onDeleteFeature={onDeleteFeature}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Alert severity="info">
                Özelliklerin sırasını değiştirmek için sürükleyip bırakabilirsiniz.
            </Alert>
            <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                <Button variant="contained" onClick={onAddFeature}>Yeni Özellik Ekle</Button>
            </Box>
        </Stack>
    )
}

export default FeatureDataGrid