import { NavLink as RouterLink, useLocation } from 'react-router-dom'
import { Box, Breadcrumbs, Link } from '@mui/material'
import React from 'react'
import { getBreadMatch } from './paths'
import { useTranslate } from 'react-admin'
import SvgIcon from '@mui/material/SvgIcon'
import NavigateNextIcon from '@mui/icons-material/NavigateNextRounded'
import { HomeRounded } from '@mui/icons-material'
import { useGlobalState } from './global-context'
import Typography from '@mui/material/Typography'

export function BreadMe() {
    const location = useLocation()
    const translate = useTranslate()
    const { state } = useGlobalState()
    const path = location.pathname

    const paths = path.split('/').filter((x) => x)

    return (
        <Box sx={{
            padding: 5,
            marginLeft: '10px',
            borderRadius: '5px',
            minWidth: '150px',
            display: 'table-cell',
        }}>
            <Breadcrumbs aria-label="Breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                {paths.length > 0 ? (
                    paths.map((value, index) => {
                        const last = index === paths.length - 1
                        const to = `/${paths.slice(0, index + 1).join('/')}`
                        const root = getBreadMatch(value, last, state.displays)
                        return last ? (
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                height: '24px',
                            }} key={to}>
                                {root.icon && (
                                    <SvgIcon
                                        component={root.icon}
                                        fontSize={'small'}
                                        sx={{
                                            marginRight: '5px',
                                            color: (theme => theme.palette.text.primary),
                                        }}
                                    />
                                )}
                                <Typography sx={{
                                    alignSelf: 'center',
                                    marginTop: '3px',
                                    color: (theme => theme.palette.text.primary),
                                }}>
                                    {root.translate ? translate(root.title) : root.title}
                                </Typography>
                            </Box>
                        ) : (
                            <Link color="inherit" component={RouterLink} to={to} key={to} sx={{
                                textDecoration: 'none',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    height: '24px',
                                }}>
                                    {root.icon && (
                                        <SvgIcon
                                            component={root.icon}
                                            fontSize={'small'}
                                            sx={{
                                                marginRight: '5px',
                                                color: (theme => theme.palette.text.secondary),
                                            }}
                                        />
                                    )}
                                    <Typography sx={{
                                        alignSelf: 'center',
                                        marginTop: '3px',
                                        color: (theme => theme.palette.text.secondary),
                                    }}>
                                        {root.translate ? translate(root.title) : root.title}
                                    </Typography>
                                </Box>
                            </Link>
                        )
                    })
                ) : (
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        height: '24px',
                    }} key={'dashboard'}>
                        <HomeRounded fontSize={'small'} sx={{
                            marginRight: '5px',
                            color: (theme => theme.palette.text.primary),
                        }} />
                        <Typography sx={{
                            alignSelf: 'center',
                            marginTop: '3px',
                            color: (theme => theme.palette.text.primary),
                        }}>
                            {translate('ra.page.dashboard')}
                        </Typography>
                    </Box>
                )}
            </Breadcrumbs>
        </Box>
    )
}
