import React, { useEffect, useState } from 'react'
import { useInput } from 'react-admin'
import { Button, FormControl, InputLabel, MenuItem, Popover, Select, Stack } from '@mui/material'

type Props = {
    name: string,
    label: string,
    alwaysOn?: boolean,
    defaultValue?: string,
    emptyText?: string,
    choices: Array<{ id: string | boolean, name: string }>
}

const OperatorAwareSelectFilter = ({ name, label, defaultValue, alwaysOn, choices, emptyText }: Props) => {
    const { field: { value, onChange } } = useInput({ source: name })
    const [localValue, setLocalValue] = useState({ operator: value?.operator ?? 'EQ', value: value?.value })
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    useEffect(() => {
        if (alwaysOn && defaultValue) {
            onChange({ operator: 'EQ', value: defaultValue })
        }
    }, [])

    useEffect(() => {
        const operator = value?.operator?.length === 2 ? value?.operator : 'EQ'
        setLocalValue({ operator, value: value?.value })
    }, [value])

    const handleOperatorClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleOperatorClose = () => {
        setAnchorEl(null)
    }

    const handleOperatorChange = (operator: string) => {
        const newValue = { ...localValue, operator }
        if (!localValue.value) {
            setLocalValue(newValue)
            handleOperatorClose()
            return
        }
        onChange(newValue)
        handleOperatorClose()
    }

    const handleValueChange = (event: any) => {
        const newValue = { ...localValue, value: event.target.value }
        if (localValue.operator === '') {
            setLocalValue(newValue)
            return
        } else if (!event.target.value) {
            setLocalValue(newValue)
            onChange(undefined)
            return
        }
        onChange(newValue)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'operator-popover' : undefined

    const textForOperator = (operator: string) => {
        switch (operator) {
            case 'EQ':
                return '=='
            case 'NE':
                return '!='
            default:
                return 'Seç'
        }
    }

    const operatorButton = () => {
        return <Stack sx={{ mr: 1 }} direction={'row'}>
            <Button aria-describedby={id}
                    variant="soft"
                    size={'small'}
                    sx={{
                        backgroundColor: theme => theme.palette.background.paper,
                    }}
                    onClick={handleOperatorClick}>
                {textForOperator(localValue?.operator)}
            </Button>
        </Stack>
    }

    return (
        <Stack
            direction={'row'}
            sx={{
                backgroundColor: theme => theme.palette.background.neutral,
                borderRadius: 1,
                px: 1,
            }}
            alignItems="center">
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleOperatorClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => handleOperatorChange('EQ')}>==</MenuItem>
                <MenuItem onClick={() => handleOperatorChange('NE')}>!=</MenuItem>
            </Popover>
            {operatorButton()}
            <FormControl variant="outlined"
                         size={'small'}
                         margin="dense">
                <InputLabel>{label}</InputLabel>
                <Select
                    value={localValue?.value ?? ''}
                    onChange={handleValueChange}
                    variant={'outlined'}
                    size={'small'}
                    fullWidth
                    sx={{
                        pl: 0,
                        minWidth: 100,
                    }}
                    label={label}
                >
                    <MenuItem value={undefined}>
                        {emptyText ?? 'Tümü'}
                    </MenuItem>
                    {choices.map(choice => <MenuItem key={`${choice.id}`}
                                                     value={`${choice.id}`}>{choice.name}</MenuItem>)}
                </Select>
            </FormControl>
        </Stack>
    )
}

export default OperatorAwareSelectFilter