import React from 'react'
import Icon from '@mui/icons-material/EmojiNature'
import {
    ArrayField,
    BooleanField,
    BooleanInput,
    DatagridConfigurable,
    DeleteWithConfirmButton,
    EditButton,
    FieldProps,
    Filter,
    FilterProps,
    FunctionField,
    ImageField,
    NumberField,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    usePermissions,
    useRecordContext,
} from 'react-admin'
import { ProductsListBulkActions } from '../../../products/ProductsListBulkActions'
import { ListPagination } from '../../../common/Pagination'
import { TimeField } from '../../../common/TimeField'
import { Chip } from '@mui/material'
import { ProductListActions } from '../../../products/ProductListActions'
import { SearchInput } from '../../../common/SearchInput'
import { RaFixedList } from '../../../common/ra-fixed-list'
import Button from '@mui/material/Button'
import Iconify from '../../../components/iconify'
import { Product } from '../../../service/types/product'
import { useNavigate } from 'react-router-dom'
import { CustomSelectInput } from '../../../components/ra-admin/select-input-override'

export const ProductIcon = Icon

const ProductFilters = (props: FilterProps) => (
    <Filter {...props}>
        <SearchInput notLive={false} source={'name.turkish'} alwaysOn label={'Türkçe Adı'} />
        <SearchInput notLive={false} source={'barcode'} alwaysOn label={'Barkod'} />
        <CustomSelectInput source={'available'} emptyText={'Tüm ürünler'} choices={[
            { id: true, name: 'Aktif ürünler' },
            { id: false, name: 'Kapalı ürünler' },
        ]} alwaysOn />
        <NumberInput source={'id'} name={'id'} />
        <ReferenceInput source={'category.id'} name={'category.id'} perPage={1000} reference={'categories'}>
            <SelectInput optionText={'name.turkish'} name={'category.id'} />
        </ReferenceInput>
        <NumberInput source={'stock'} name={'stock'} />
        <BooleanInput source={'enableStockManagement'} name={'enableStockManagement'} />
    </Filter>
)
export const ProductList = () => {
    const { permissions } = usePermissions()
    return (
        <RaFixedList
            pagination={<ListPagination />}
            perPage={10}
            hasCreate={true}
            filterDefaultValues={{}}
            sort={{ field: 'updatedAt', order: 'DESC' }}
            // @ts-ignore
            actions={<ProductListActions />}
            // @ts-ignore
            filters={<ProductFilters />}
        >
            <ProductGrid permissions={permissions} />
        </RaFixedList>
    )
}

function calculateMargin(record: Product) {
    if (record.price === undefined || record.purchasePrice === undefined || record.purchasePrice === 0) {
        return 0
    }
    const difference = record.price - record.purchasePrice
    return ((difference / record.purchasePrice) * 100).toFixed(0)
}

export const ProductGrid = ({ permissions }: { permissions: string }) => (
    <DatagridConfigurable
        // @ts-ignore
        bulkActionButtons={permissions === 'ROLE_ADMIN' && <ProductsListBulkActions />}>
        <TextField source={'name.turkish'} sx={{ width: '200px', display: 'block' }} />
        <ReferenceField reference={'categories'}
                        source={'categoryId'}>
            <TextField source={'name.turkish'}
                       sx={{ width: '100px', display: 'block' }} />
        </ReferenceField>
        <ReferenceField reference={'categories'} emptyText={'Boş'} source={'secondCategoryId'}>
            <TextField source={'name.turkish'} />
        </ReferenceField>
        <TextField source={'purchasePrice'}
                   sx={{ width: '70px', display: 'block' }}
        />
        <TextField source={'price'}
                   sx={{ width: '70px', display: 'block' }}
        />
        <TextField source={'newPrice'}
                   sx={{ width: '90px', display: 'block' }}
        />
        <FunctionField
            sortBy={'margin'}
            label={'Margin'}
            render={(record: Product) => record !== undefined && `${calculateMargin(record)}%`}
        />
        <TextField source={'stock'} />
        <TextField source={'amount'} />
        <ReferenceField reference={'productUnits'} source={'unitId'}>
            <TextField source={'name'} />
        </ReferenceField>
        <FunctionField
            source={'tax'}
            render={(record: Product) => (record?.tax == null ? '0%' : `${record.tax}%`)}
        />
        <BooleanField source={'available'} />
        <NumberField source={'maximumOrderLimit'} sx={{ width: '200px', display: 'block' }} />
        <NumberField source={'minimumOrderLimit'} sx={{ width: '200px', display: 'block' }} />
        <ArrayField source="barcodes">
            <SingleFieldList>
                <FunctionField render={(record: string) => <Chip label={record} />} />
            </SingleFieldList>
        </ArrayField>
        <FeaturesButton label={'Özellikler'} />
        <ShowButton />
        <EditButton />
        <DeleteWithConfirmButton />
    </DatagridConfigurable>
)

const FeaturesButton = ({ label }: FieldProps) => {
    const record = useRecordContext()
    const navigate = useNavigate()

    return (
        <Button
            onClick={() => navigate(`/products/${record.id}/features`, { state: { productId: record.id } })}
            variant={'contained'}
            size={'small'}
            color={'secondary'}
            startIcon={<Iconify icon={'fluent:extension-24-filled'} />}
        >
            {label}
        </Button>
    )
}
export const ProductShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source={'name.english'} />
            <TextField source={'name.turkish'} />
            <TextField source={'description.english'} />
            <TextField source={'description.turkish'} />
            <ReferenceField reference={'categories'} source={'categoryId'}>
                <TextField source={'name.turkish'} />
            </ReferenceField>
            <ReferenceField reference={'categories'} source={'secondCategoryId'}>
                <TextField source={'name.turkish'} />
            </ReferenceField>
            <TextField source={'purchasePrice'} />
            <TextField source={'price'} />
            <TextField source={'newPrice'} />
            <TextField source={'maximumOrderLimit'} />
            <TextField source={'stock'} />
            <TextField source={'lowStockLimit'} />
            <TimeField source={'expirationDate'} />
            <ImageField source={'image'} />
            <BooleanField source={'enableStockManagement'} />
            <TextField source={'tax'} />
            <FunctionField
                source={'tax'}
                render={(record: Product) => (record?.tax == null ? '0%' : `${record.tax}%`)}
            />
            <ArrayField source="barcodes">
                <SingleFieldList>
                    <FunctionField render={(record: string) => <Chip label={record} />} />
                </SingleFieldList>
            </ArrayField>
            <ReferenceField reference={'productUnits'} source={'unitId'}>
                <TextField source={'name'} />
            </ReferenceField>
            <TextField source={'amount'} />
        </SimpleShowLayout>
    </Show>
)

export const ProductTranslations = {
    tr: {
        products: {
            name: 'Ürünler',
            general: 'Genel',
            names: 'Adı',
            fields: {
                name: {
                    english: 'İngilizce Adı',
                    turkish: 'Türkçe Adı',
                },
                amount: 'Miktar',
                unitId: 'Birim',
                expirationDate: 'Son kullanma tarihi',
                lowStockLimit: 'Düşük Stok Limiti',
                image: 'Resim',
                available: 'Satışta',
                description: {
                    english: 'İngilizce Açıklama',
                    turkish: 'Türkçe Açıklama',
                },
                category: {
                    id: 'Kategori',
                    name: {
                        turkish: 'Kategori',
                    },
                },
                categoryId: 'Kategori',
                secondCategoryId: 'İkinci Kategori',
                price: 'Satiş fiyat',
                stock: 'Stok',
                award: 'Kredit',
                newPrice: 'İndirimli fiyat',
                enableStockManagement: 'Stok Yönetimi Aktif?',
                purchasePrice: 'Alış fiyatı',
                maximumOrderLimit: 'Maksimum sipariş limiti',
                minimumOrderLimit: 'Minimum sipariş limiti',
                tax: 'Vergi',
                barcodes: 'Barkodlar',
            },
        },
    },
}
