export type LoginResponse = {
    token: string,
    method: VerificationMethod,
}

export enum VerificationMethod {
    googleAuth = 'GOOGLE_AUTH',
    email = 'EMAIL',
}

export type LoggedUser = {
    name: string,
    username: string,
    marketId: string,
    token: string,
    authority: string,
}
export type UserProfile = {
    name: string,
    facebook?: string,
    twitter?: string,
    instagram?: string,
    website?: string,
    email: string,
    description: string,
    image?: string,
    phone: string,
    address: string,
}

export type ProfileUpdateRequest = {
    facebook?: string,
    twitter?: string,
    instagram?: string,
    website?: string,
    description: string,
    image?: string,
    email: string,
}

export type Driver = {
    id: string,
    name: string,
    username: string,
    email: string,
}