import React from 'react'
import Icon from '@mui/icons-material/Extension'
import {
    Button,
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    ImageField,
    maxLength,
    ReferenceManyField,
    required,
    Show,
    ShowButton,
    Tab,
    TabbedForm,
    TabbedShowLayout,
    TextField,
    TextInput,
} from 'react-admin'
import { CategoryGrid } from './category'
import { ListPagination } from '../common/Pagination'
import { SearchInput } from '../common/SearchInput'
import { RaFixedList } from '../common/ra-fixed-list'
import ImageUploadInput from '../common/image-upload-input'
import { useNavigate } from 'react-router-dom'
import { DefaultListActions } from '../common/default-list-action'
import RearrangeButtonView from '../common/rearrange-button-view'

export const DepartmentIcon = Icon
export const DepartmentCreate = () => (
    <Create>
        <CreateOrEdit />
    </Create>
)

export const DepartmentEdit = () => (
    <Edit>
        <CreateOrEdit />
    </Edit>
)

const DepartmentFilters = (props) => (
    <Filter {...props}>
        <SearchInput source={'name.english'} alwaysOn label={'English Name'} />
        <SearchInput source={'name.turkish'} alwaysOn label={'Turkish Name'} />
    </Filter>
)
export const DepartmentList = () => {
    const navigate = useNavigate()
    return <RaFixedList
        actions={<DefaultListActions>
            <>
                <Button onClick={() => navigate('/departments/rearrange')} label={'Sıralama'} startIcon={<Icon />} />
            </>
        </DefaultListActions>}
        pagination={<ListPagination />}
        perPage={50}
        sort={{ field: 'createdAt', order: 'DESC' }}
        filters={<DepartmentFilters />}
    >
        <Datagrid bulkActionButtons={false}>
            <TextField source={'name.turkish'} />
            <TextField source={'description.english'} />
            <RearrangeButtonView path={'categories'}>
                Kategorileri Sırala
            </RearrangeButtonView>
            <ShowButton />
            <EditButton />
            <DeleteWithConfirmButton />
        </Datagrid>
    </RaFixedList>
}
export const DepartmentShow = () => (
    <Show>
        <TabbedShowLayout>
            <Tab label={'resources.departments.general'}>
                <TextField source={'name.turkish'} />
                <TextField source={'name.english'} />
                <TextField source={'description.english'} />
                <TextField source={'description.turkish'} />
                <ImageField source={'image'} />
            </Tab>
            <Tab label={'resources.categories.name'}>
                <ReferenceManyField reference={'categories'} target={'department.id'} addLabel={false}>
                    <CategoryGrid />
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
)
const CreateOrEdit = () => (
    <TabbedForm syncWithLocation={false} redirect={'list'}>
        <TabbedForm.Tab label={'resources.departments.general'}>
            <TextInput source={'name.english'} name={'name.english'} validate={[required(), maxLength(255)]} />
            <TextInput source={'name.turkish'} name={'name.turkish'} validate={[required(), maxLength(255)]} />

            <TextInput source={'description.english'} name={'description.english'} multiline
                       validate={[required(), maxLength(1000)]} />
            <TextInput source={'description.turkish'} name={'description.turkish'} multiline
                       validate={[required(), maxLength(1000)]} />
            <ImageUploadInput source={'image'} required={true} />
        </TabbedForm.Tab>
    </TabbedForm>
)

export const DepartmentTranslations = {
    tr: {
        departments: {
            name: 'Üst Kategoriler',
            general: 'Genel',
            fields: {
                name: {
                    english: 'İngilizce Adı',
                    turkish: 'Türkçe Adı',
                },
                image: 'Resim',
                description: {
                    english: 'İngilizce Açıklama',
                    turkish: 'Türkçe Açıklama',
                },
            },
        },
    },
}
