import raTr from './raTr'
import { CategoryTranslations } from '../categories/category'
import { DepartmentTranslations } from '../categories/department'
import { CityTranslations } from '../address/City'
import { DistrictTranslations } from '../address/District'
import { TownTranslations } from '../address/Town'
import { ProductTranslations } from '../features/product/list/product-list'
import { CampaignTranslations } from '../products/Campaign'
import { NotificationEmailTranslations } from '../settings/NotificationEmail'
import { ConfigurationStrings } from '../settings/Configuration'
import { FaqTranslations } from '../features/faq/Faq'
import { MenuTranslations } from '../layout/dashboard/config-navigation'
import { PendingOrderTranslations } from '../dashboard/pending/pending-order'
import { MarkOutOfStockTranslations } from '../products/MarkOutOfStockView'
import { ProductsListActionTranslations } from '../products/ProductsListBulkActions'
import { ProductUnitTranslations } from '../features/product/list/product-unit'
import { DriverTranslations } from '../features/driver/driver+'
import { OrderTranslations } from '../features/order/order+'
import { OrderReportTranslations } from '../features/report/order-report+'
import { PaymentListTranslation } from '../features/order/payment/payment-list+'
import { DateRangeInputTranslations } from '../common/date/date-range-input'

export default {
    ...raTr,
    login: {
        loginFailed: 'Giriş başarısız. Kullanıcı adını ve şifreyi kontrol ediniz',
    },
    resources: {
        ...CategoryTranslations.tr,
        ...DepartmentTranslations.tr,
        ...CityTranslations.tr,
        ...DistrictTranslations.tr,
        ...TownTranslations.tr,
        ...ProductTranslations.tr,
        ...CampaignTranslations.tr,
        ...OrderTranslations.tr,
        ...NotificationEmailTranslations.tr,
        ...ConfigurationStrings.tr,
        ...FaqTranslations.tr,
        ...ProductUnitTranslations.tr,
        ...DriverTranslations.tr,
        ...PaymentListTranslation.tr,
    },
    ...MenuTranslations.tr,
    ...PendingOrderTranslations.tr,
    ...MarkOutOfStockTranslations.tr,
    ...ProductsListActionTranslations.tr,
    ...OrderReportTranslations.tr,
    ...DateRangeInputTranslations.tr,
}
