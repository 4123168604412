import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Unstable_Grid2'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useResponsive } from 'src/hooks/use-responsive'
import { useRouter } from 'src/routes/hooks'
import FormProvider, { RHFAutocomplete, RHFSwitch, RHFTextField, RHFUpload } from 'src/components/hook-form'
import { ProductInsertRequest } from '../../service/types/product'
import { useNotify } from 'react-admin'
import CategoryReferenceInput from '../category/category-reference-input'
import ProductUnitReferenceInput from './product-unit-reference-input'
import RHFDateField from 'src/components/hook-form/rhf-date-field'
import { useProductService } from '../../service/crud/product-service'
import { useUploadService } from '../../service/upload-service'
import { NewProductSchema, productRestToSchema, productSchemaToRest } from './product-edit-form+'
import Label from '../../components/label'
import { useIsCompany } from '../../login/user-provider'

type Props = {
    currentProduct?: ProductInsertRequest;
    id?: string;
};

export default function ProductNewEditForm({ currentProduct, id }: Props) {
    const router = useRouter()
    const mdUp = useResponsive('up', 'md')
    const notify = useNotify()
    const productService = useProductService()
    const uploadService = useUploadService()
    const isCompany = useIsCompany()

    const defaultValues = useMemo(
        () => ({ ...productRestToSchema(currentProduct) }),
        [currentProduct],
    )

    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues,
    })

    const {
        reset,
        watch,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = methods

    const values = watch()

    useEffect(() => {
        if (currentProduct) {
            reset(defaultValues)
        }
    }, [currentProduct, defaultValues, reset])


    const onSubmit = handleSubmit(async (data) => {
        try {
            let image
            if (typeof data.image !== 'string') {
                image = await uploadService.uploadImage(data.image as File)
                setValue('image', image)
            } else {
                image = data.image
            }
            const request: ProductInsertRequest = productSchemaToRest(data, image)
            id ? await productService.update(id, request) : await productService.insert(request)
            notify(currentProduct ? 'Ürün güncelldi' : 'Ürün oluşturuldu', { type: 'success' })
            router.push('/products')
        } catch (error) {
            notify(`Ürün oluşturulurken bir hata oluştu. [${error.message}]`, { type: 'error' })
        }
    })

    const handleDrop = useCallback(
        async (acceptedFiles: File[]) => {
            if (typeof values.image === 'string') {
                await uploadService.deleteImage(values.image).then(() => {
                    //
                }).catch(() => {
                    //
                })
            }
            const first = acceptedFiles[0]
            const newFile = Object.assign(first, {
                preview: URL.createObjectURL(first),
            })
            setValue('image', newFile, { shouldValidate: true })
        },
        [setValue, values.image],
    )

    const handleRemoveAllFiles = useCallback(async () => {
        if (typeof values.image === 'string') {
            await uploadService.deleteImage(values.image).then(() => {
                notify('Resim silindi', { type: 'info' })
                setValue('image', '')
            }).catch((error) => {
                notify(`Resim silinemedi. ${error.message}`, { type: 'error' })
            })
        }
    }, [setValue])

    const margin = useMemo(() => {
        return calculateMargin(values.price, values.purchasePrice)
    }, [values.price, values.purchasePrice])

    const discountedMargin = useMemo(() => {
        return calculateMargin(values.priceSale, values.purchasePrice)
    }, [values.priceSale, values.purchasePrice])

    const renderDetails = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Detaylar
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Ürün detayları ve açıklamaları...
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Detaylar" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <Stack spacing={3} direction={'row'}>
                            <RHFTextField name="name" label="Ürün adı" />
                            <RHFTextField name="englishName" label="Ürünün İngilizce adı" />
                        </Stack>
                        <Stack spacing={3} direction={'row'}>
                            <RHFTextField name="description" label="Açıklama" multiline rows={4} />
                            <RHFTextField name="englishDescription" label="Ürün İngilizce açıklaması" multiline
                                          rows={4} />
                        </Stack>

                        <Stack spacing={1.5}>
                            <Typography variant="subtitle2">Resim</Typography>
                            <RHFUpload
                                thumbnail
                                disableMultiple
                                name="image"
                                maxSize={3145728}
                                onDelete={handleRemoveAllFiles}
                                onDrop={handleDrop}
                            />
                        </Stack>
                    </Stack>
                </Card>
            </Grid>
        </>
    )

    const renderProperties = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Özellikler
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Ek işlevler ve özellikler...
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Özellikler" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <Box
                            columnGap={2}
                            rowGap={3}
                            display="grid"
                            gridTemplateColumns={{
                                xs: 'repeat(1, 1fr)',
                                md: 'repeat(2, 1fr)',
                            }}
                        >
                            <ProductUnitReferenceInput name={'unitId'} label={'Ürün Birim'} />

                            <RHFTextField name="amount" label="Miktar" />

                            <RHFTextField
                                name="quantity"
                                label="Stok Adedi"
                                placeholder="0"
                                type="number"
                                InputLabelProps={{ shrink: true }}
                            />

                            <RHFTextField
                                name="lowStockLimit"
                                label="Düşük Stok Limiti"
                                placeholder="0"
                                type="number"
                                InputLabelProps={{ shrink: true }}
                            />

                            <CategoryReferenceInput name="categoryId" label="Kategori" />
                            <CategoryReferenceInput name="secondCategoryId" label="İkinci Kategori" />

                            <RHFTextField name="minimumOrderLimit"
                                          type="number"
                                          placeholder="0"
                                          InputLabelProps={{ shrink: true }}
                                          label="Minimum Sipariş Limiti" />
                            <RHFTextField name="maximumOrderLimit"
                                          type="number"
                                          placeholder="0"
                                          InputLabelProps={{ shrink: true }}
                                          label="Maksimum Sipariş Limiti" />
                            <RHFDateField name="expirationDate"
                                          label="Son Kullanma Tarihi" />

                        </Box>

                        <RHFAutocomplete
                            name="barcodes"
                            label="Barkodlar"
                            placeholder="+ Barkodlar"
                            multiple
                            freeSolo
                            options={[]}
                            getOptionLabel={(option) => option}
                            renderOption={(props, option) => (
                                <li {...props} key={option}>
                                    {option}
                                </li>
                            )}
                            renderTags={(selected, getTagProps) =>
                                selected.map((option, index) => (
                                    <Chip
                                        {...getTagProps({ index })}
                                        key={option}
                                        label={option}
                                        size="small"
                                        color="info"
                                        variant="soft"
                                    />
                                ))
                            }
                        />

                        <Divider sx={{ borderStyle: 'dashed' }} />
                    </Stack>
                </Card>
            </Grid>
        </>
    )

    const renderPricing = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Fiyatlandırma
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Fiyatla ilgili veri girişleri
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Fiyatlandırma" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        {!isCompany && <RHFTextField
                            name="purchasePrice"
                            label="Urün Aliş Fiyatı"
                            placeholder="0.00"
                            type="number"
                            disabled={isCompany && values.purchasePrice <= 0}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box component="span" sx={{ color: 'text.disabled' }}>
                                            ₺
                                        </Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        }
                        <RHFTextField
                            name="price"
                            label="Urün Satış Fiyatı"
                            placeholder="0.00"
                            type="number"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box component="span" sx={{ color: 'text.disabled' }}>
                                            ₺
                                        </Box>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <RHFTextField
                            name="priceSale"
                            label="Indirimli Satış Fiyatı"
                            placeholder="0.00"
                            type="number"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box component="span" sx={{ color: 'text.disabled' }}>
                                            ₺
                                        </Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Stack spacing={1} direction={'row'}>
                            {margin && <Label variant={'soft'}
                                              color={'success'}>Kar marjını: {margin}</Label>
                            }
                            {discountedMargin && <Label variant={'soft'} color={'error'}>İndirimli Kar
                                marjını: {discountedMargin}</Label>}
                        </Stack>
                        <FormControlLabel
                            control={<Switch checked={true} disabled />}
                            label="Fiyata Vergi Dahildir"
                        />

                        <RHFTextField
                            name="tax"
                            label="Vergi (%)"
                            placeholder="0.00"
                            type="number"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box component="span" sx={{ color: 'text.disabled' }}>
                                            %
                                        </Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Stack>
                </Card>
            </Grid>
        </>
    )

    const renderActions = (
        <>
            {mdUp && <Grid md={4} />}
            <Grid xs={12} md={8}>
                <Card>
                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFSwitch label="Ürün Satışa Hazır"
                                   name={'available'}
                                   sx={{ flexGrow: 1, pl: 3 }}
                        />
                        <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                            {!currentProduct ? 'Ürün Oluştur' : 'Değişiklikleri Kaydet'}
                        </LoadingButton>
                    </Stack>
                </Card>
            </Grid>
        </>
    )

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Grid container spacing={3} sx={{ mt: 3 }}>
                {renderDetails}

                {renderProperties}

                {renderPricing}

                {renderActions}
            </Grid>
        </FormProvider>
    )
}


function calculateMargin(price?: number, purchasePrice?: number) {
    if (!price || !purchasePrice) {
        return ''
    }
    if (price === 0 || purchasePrice === 0) {
        return ''
    }
    const difference = price - purchasePrice
    return ((difference / purchasePrice) * 100).toFixed(2) + '%'
}