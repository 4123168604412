import HttpError from './HttpErrors'
import { ApiAuthProvider } from '../service/api-auth-provider'
import { globalConfig } from '../global-config'

export const fetchJson = (url, options = {}, unauthenticated = false) => {
    const requestHeaders = options.headers !== undefined ? options.headers : new Headers()
    if (!requestHeaders.has('Content-Type') &&
        !(options && options.body && options.body instanceof FormData)) {
        requestHeaders.set('Content-Type', 'application/json')
    }
    if (!unauthenticated) {
        const user = ApiAuthProvider.INSTANCE.getSession()
        if (user) {
            requestHeaders.set('Authorization', `Bearer ${user.token}`)
        }
    }
    requestHeaders.append('Access-Control-Allow-Origin', '*')
    requestHeaders.append('X-API-KEY', globalConfig.api.apiKey)
    requestHeaders.append('Accept-Language', 'tr')
    return fetch(url, { ...options, headers: requestHeaders, redirect: 'follow' })
        .then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            })),
        )
        .then(({ status, statusText, headers, body }) => {
            let json
            try {
                json = JSON.parse(body)
            } catch (e) {
                // not json, no big deal
            }
            if (status < 200 || status >= 300) {
                return Promise.reject(
                    new HttpError(
                        (json && json.message) || statusText,
                        status,
                        json,
                    ),
                )
            }
            if (json === undefined) {
                json = { id: '' }
            }
            return { status, headers, body, json }
        })
}


const isValidObject = value => {
    if (!value) {
        return false
    }

    const isArray = Array.isArray(value)
    const isBuffer = Buffer.isBuffer(value)
    const isObject =
        Object.prototype.toString.call(value) === '[object Object]'
    const hasKeys = !!Object.keys(value).length

    return !isArray && !isBuffer && isObject && hasKeys
}

export const flattenObject = (value, path = []) => {
    if (isValidObject(value)) {
        return Object.assign(
            {},
            ...Object.keys(value).map(key =>
                flattenObject(value[key], path.concat([key])),
            ),
        )
    } else {
        return path.length ? { [path.join('.')]: value } : value
    }
}