import React from 'react'
import { List, SelectInput, useListContext, useRefresh } from 'react-admin'
import OrderTable from './order-table'
import Icon from '@mui/icons-material/ShoppingBasket'
import Card from '@mui/material/Card'
import { OrderStatus } from '../../service/types/order'
import OperatorAwareSelectFilter from './operator-aware=field-filer'

export const OrderIcon = Icon

const filters = [
    <OperatorAwareSelectFilter name="status"
                               alwaysOn
                               label="Sipariş Durumu"
                               key={'source'} choices={[
        { id: OrderStatus.PENDING, name: 'Beklemede' },
        { id: OrderStatus.CONFIRMED, name: 'Hazırlanıyor' },
        { id: OrderStatus.ONWAY, name: 'Yolda' },
        { id: OrderStatus.DELIVERED, name: 'Teslim Edildi' },
        { id: OrderStatus.CANCELLED, name: 'İptal Edildi' },
    ]} />,
    <OperatorAwareSelectFilter name="paymentType"
                               choices={[
                                   { id: 'CASH', name: 'Nakit' },
                                   { id: 'CREDIT_CARD', name: 'Kredi Kartı' },
                                   { id: 'CREDIT_CARD_DOOR', name: 'Kapida Kredi Kartı' }]}
                               key={'paymentType'} alwaysOn label={'Ödeme Türü'} />,
    <SelectInput
        name={'timeStatus'}
        key={'timeStatus'}
        source={'timeStatus'}
        choices={[
            { id: 'FUTURE', name: 'İleri Tarihi Siparişler' },
            { id: 'PAST', name: 'Geçmiş Tarihi Siparişler' },
            { id: 'CURRENT', name: 'Aktif Siparişleri' },
        ]}
        label={'İleri Tarihi Siparişler'} alwaysOn />,
]


export const OrdersList = () => {
    return <List exporter={false}
                 filters={filters}
                 storeKey={false}
                 pagination={false}>
        <OrdersGird />
    </List>
}

export const OrdersGird = () => {
    const { data, page, perPage, setPage, setPerPage, total, setSort, sort } = useListContext()
    const refresh = useRefresh()
    if (data == null) {
        return <></>
    }
    return <Card sx={{ maxHeight: '100%' }}>
        <OrderTable orders={data}
                    refresh={refresh}
                    page={page}
                    total={total}
                    rowsPerPage={perPage}
                    onSort={setSort}
                    order={sort.order}
                    orderBy={sort.field}
                    onRowsPerPageChange={setPerPage}
                    onChangePage={setPage} />
    </Card>
}