import { SequencedResource, SequenceItem } from '../../service/types/sequence'
import React, { useEffect } from 'react'
import { useSequenceService } from '../../service/sequence-service'
import { useBoolean } from '../../hooks/use-boolean'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Iconify from '../../components/iconify'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { RearrangeTableView } from './rearrange-table-view'
import LoadingButton from '@mui/lab/LoadingButton'
import { useNotify } from 'react-admin'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'

type RearrangeViewProps = {
    resource: SequencedResource | string
    search?: string
    richText?: boolean
}
export default function RearrangeView({ resource, search, richText }: RearrangeViewProps) {
    const service = useSequenceService()
    const [data, setData] = React.useState<SequenceItem[]>([])
    const loading = useBoolean(true)
    const theme = useTheme()
    const notify = useNotify()
    useEffect(() => {
        loading.onTrue()
        const x = async () => {
            const data = await service.getSequenced(resource, search)
                .catch(() => {
                    return []
                })
            setData(data)
            loading.onFalse()
        }
        void x()
    }, [resource])
    const onSaveTap = async () => {
        loading.onTrue()
        await service.updateSequence(resource, data)
            .then(() => {
                notify('Sıralama başarıyla güncellendi.', { type: 'success' })
            })
            .catch(() => {
                notify('Sıralama güncellenirken bir hata oluştu.', { type: 'error' })
            }).finally(loading.onFalse)
    }
    return <Card sx={{ overflowY: 'auto' }}>
        <CardHeader title={<Stack>
            <Typography variant={'h6'}>Sıralama</Typography>
            <Typography variant={'body2'} color={'textSecondary'}>
                Sıralamak istediğiniz öğeleri sürükleyip bırakarak sıralayabilirsiniz.
            </Typography>
        </Stack>} />
        <Stack spacing={2} sx={{ p: 2 }}>
            <Box sx={{ p: 2 }}>
                <RearrangeTableView data={data} setData={setData} richText={richText} />
            </Box>

            <AppBar
                position={'sticky'}
                color={'inherit'}
                variant={'elevation'}
                sx={{ top: 'auto', bottom: 0, borderRadius: 0 }}
            >
                <Toolbar sx={{ m: 1, display: 'flex', alignItems: 'center' }}>
                    <LoadingButton
                        color={'primary'}
                        loading={loading.value}
                        onClick={onSaveTap}
                        startIcon={<Iconify icon={'lets-icons:check-fill'} />}
                        variant={'contained'}
                        sx={{ fontWeight: theme.typography.fontWeightSemiBold }}
                    >
                        Kaydet
                    </LoadingButton>
                </Toolbar>
            </AppBar>
        </Stack>
    </Card>

}