import React, { useState } from 'react'
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { ProductFeatureCategory } from '../../../service/types/product-feature'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { fCurrencyTl } from '../../../utils/format-number'
import Button from '@mui/material/Button'
import Iconify from '../../../components/iconify'
import Label from '../../../components/label'

type ProductFeatureCategoryItemProps = {
    category: ProductFeatureCategory;
    onEdit: (category: ProductFeatureCategory) => void;
};

export default function ProductFeatureCategoryItem({ category, onEdit }: ProductFeatureCategoryItemProps) {
    const [open, setOpen] = useState(false)
    const handleEdit = () => {
        onEdit(category)
    }
    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell>{category.name.turkish}</TableCell>
                <TableCell>{category.description.turkish}</TableCell>
                <TableCell>{category.minSelectable}</TableCell>
                <TableCell>{category.maxSelectable}</TableCell>
                <TableCell>{category.features.length}</TableCell>
                <TableCell>
                    <Button
                        aria-label="edit"
                        size="small"
                        color={'primary'}
                        startIcon={<Iconify icon="mdi:edit" />}
                        variant="text"
                        onClick={handleEdit}
                    >
                        Düzenle
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1} marginLeft={4}>
                            <Typography variant="h6" gutterBottom component="div">
                                Özellikler
                            </Typography>
                            <Table size="small" aria-label="features">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Adi</TableCell>
                                        <TableCell>Altyazı</TableCell>
                                        <TableCell>Fiyat</TableCell>
                                        <TableCell>Varsayılan olarak aktif</TableCell>
                                        <TableCell>Silinmız (Üründan çıkarılamaz)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {category.features.map((feature) => (
                                        <TableRow key={feature.id}>
                                            <TableCell>{feature.name.turkish}</TableCell>
                                            <TableCell>{feature.description.turkish}</TableCell>
                                            <TableCell><Label variant={'soft'} color={'primary'}>{fCurrencyTl(feature.price)}</Label></TableCell>
                                            <TableCell>{feature.activeByDefault ? 'Evet' : 'Hayır'}</TableCell>
                                            <TableCell>{feature.mandatory ? 'Evet' : 'Hayır'}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}