import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import { bgBlur } from 'src/theme/css'
import { useOffSetTop } from 'src/hooks/use-off-set-top'
import { useResponsive } from 'src/hooks/use-responsive'
import SvgColor from 'src/components/svg-color'
import { useSettingsContext } from 'src/components/settings'
import { HEADER, NAV } from '../config-layout'
import { AccountPopover, SettingsButton } from '../_common'
import React, { useEffect, useRef, useState } from 'react'
import { BreadMe } from '../bread/BreadMe'
import Logo from 'src/components/logo'
import SystemStatusButton from '../system-status/system-status-button'
import RefreshButton from '../_common/refresh-button'
import NotificationsPopover from '../../dashboard/notifications-popover'
import { Order } from '../../service/types/order'
import { NotificationItem } from '../../service/types/common'
import { useLiveOrderService } from '../../service/live-order-service'
import NewOrderDialog from '../../dashboard/notifications-popover/new-order-dialog'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../../login/user-provider'
import { useOrderService } from '../../service/order-service'
import { ApiAuthProvider } from '../../service/api-auth-provider'
import { useLoading } from 'ra-core'
import { RotatingLines } from 'react-loader-spinner'

type Props = {
    onOpenNav?: VoidFunction
}

export default function Header({ onOpenNav }: Props) {
    const theme = useTheme()
    const loading = useLoading()
    const settings = useSettingsContext()
    const isNavHorizontal = settings.themeLayout === 'horizontal'
    const isNavMini = settings.themeLayout === 'mini'
    const lgUp = useResponsive('up', 'lg')
    const offset = useOffSetTop(HEADER.H_DESKTOP)
    const offsetTop = offset && !isNavHorizontal
    const [notifications, setNotifications] = useState<NotificationItem[]>([])
    const [order, setOrder] = useState<Order | null>(null)
    const navigate = useNavigate()
    const user = useUserContext()
    const orderService = useOrderService()
    const orders = useRef<Order[]>([])
    const onNewOrder = (order: Order, scheduled: boolean) => {
        const notification: NotificationItem = {
            badge: true,
            badgeMessage: 'Yeni Sipariş',
            badgeSeverity: 'success',
            date: new Date(order.pendingTime).toISOString(),
            message: `${order.customer.name} adlı müşteriden yeni bir sipariş geldi`,
            unread: true,
            id: order.id,
            subject: `${order.customer.name}`,
        }
        setNotifications(prevState => [notification, ...prevState])
        setOrder(order)
        sendBrowserNotification(order)
        if (!scheduled) {
            orders.current.push(order)
        }
    }

    const loadData = async (showNotification: boolean) => {
        const auth = ApiAuthProvider.INSTANCE.getSession()
        if (!auth) {
            return
        }
        await orderService.getPending()
            .then((res) => {
                if (showNotification) {
                    const current = orders.current
                    const newOrder = res.find((order) => !current.find((o) => o.id === order.id))
                    if (newOrder) {
                        onNewOrder(newOrder, true)
                    }
                }
                orders.current.splice(0, orders.current.length)
                orders.current.push(...res)
            })
            .catch(() => {
                // ignore
            })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            void loadData(true)
        }, 60_000)
        void loadData(false)
        return () => {
            clearInterval(interval)
        }
    }, [])

    function sendBrowserNotification(order: Order) {
        const hasPermission = Notification.permission === 'granted'
        if (!hasPermission) {
            askNotificationPermission()
            return
        }
        const title = 'Yeni Sipariş | Nerde Kaldı'
        const options = {
            body: `${user?.name} Markette yeni bir sipariş geldi`,
            icon: '/logo.png',
            vibrate: [200, 100, 200, 100, 200, 100, 200],
            tag: order.id,
            renotify: true,
        }
        const notification = new Notification(title, options as NotificationOptions)
        notification.onclick = () => {
            window.focus()
            notification.close()
            navigate(`/orders/show/${order.id}`, { state: { id: order.id } })
        }

    }

    function askNotificationPermission() {
        if (!('Notification' in window)) {
            console.log('This browser does not support notifications.')
            return
        }
        void Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Notification permission granted.')
            }
        })
    }

    useLiveOrderService({
        onNewOrder: order1 => {
            onNewOrder(order1, false)
        },
    })

    const handleMarkAllAsRead = () => {
        const read = notifications.map((notification) => ({ ...notification, unread: false }))
        setNotifications(read)
    }

    const renderContent = (
        <>
            {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

            {!lgUp && (
                <IconButton onClick={onOpenNav}>
                    <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
                </IconButton>
            )}

            <BreadMe />

            <Stack
                flexGrow={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={{ xs: 0.5, sm: 1 }}
            >
                {loading && <RotatingLines strokeColor={theme.palette.primary.main} strokeWidth="3" animationDuration="0.75" width="30" visible={true} />}
                <SystemStatusButton />
                <RefreshButton />
                <NotificationsPopover notifications={notifications} onMarkAllAsRead={handleMarkAllAsRead} />
                <SettingsButton />
                <AccountPopover />
            </Stack>
        </>
    )

    return (
        <>
            <AppBar
                sx={{
                    height: HEADER.H_MOBILE,
                    zIndex: theme.zIndex.appBar + 1,
                    ...bgBlur({
                        color: theme.palette.background.default,
                    }),
                    transition: theme.transitions.create(['height'], {
                        duration: theme.transitions.duration.shorter,
                    }),
                    ...(lgUp && {
                        width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
                        height: HEADER.H_DESKTOP,
                        ...(offsetTop && {
                            height: HEADER.H_DESKTOP_OFFSET,
                        }),
                        ...(isNavHorizontal && {
                            width: 1,
                            bgcolor: 'background.default',
                            height: HEADER.H_DESKTOP_OFFSET,
                            borderBottom: `dashed 1px ${theme.palette.divider}`,
                        }),
                        ...(isNavMini && {
                            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
                        }),
                    }),
                }}
            >
                <Toolbar
                    sx={{
                        height: 1,
                        px: { lg: 5 },
                    }}
                >
                    {renderContent}
                </Toolbar>
            </AppBar>
            {order && <NewOrderDialog order={order} onClose={() => {
                setOrder(null)
            }} />}
        </>

    )
}
