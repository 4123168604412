// This file is generated automatically at Sat Nov 16 20:39:41 CET 2024. Do not modify it manually.
export const globalConfig = {
   api: {
base : "https://dev-store-api.nerdekaldi.app",
clientId : "14B32E3A-6AB1-4C7E-AEE0-E768A3080BBD",
clientSecret : "88080774-0739-4FE9-B381-D3574CE2A450",
apiKey : "72DFE303-23EC-47E9-A0A8-F88A5B1B4CE1",
siteKey : "c595d5a3-11a2-4960-b376-57cc8977a036",
   }
}
